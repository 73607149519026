import { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAppContext } from "fusion-context";
import debounce from "lodash.debounce";
import get from "lodash.get";
import set from "lodash.set";
import { useBreakpoints } from "~/shared-components/BreakpointContext";
import { useClick, useUnload } from "~/components/utilities/use-event-handlers";
import { hasLocalStorage } from "~/components/utilities/hasLocalStorage";
import {
  getIndex,
  harvestCandidates
} from "~/components/layouts/homepage.helpers";
import { useMultiviewTabData } from "~/components/features/fronts/multi-view-nav/constants";
import { useAkamaiVariant } from "~/components/utilities/use-variant";

// NOTE: Needed cuz stitches uses this outside a component. mini-ugh
export const HEIGHT = "38px"; // height of multiview tabs

const defaultValue = {
  isMultiView: false
};

const MultiViewContext = createContext(defaultValue);

const storageKey = "mvActiveTab";

const mandatoryTabs = ["top-stories"];

const fixTabsToShow = (tabs = []) => {
  return [...mandatoryTabs, ...tabs].filter(
    (_, i, self) => self.indexOf(_) === i
  ); // uniquify
};

const getInitialTab = (tabData, variant) => {
  const tabIds = tabData.map(({ tabId }) => tabId);
  if (variant === 1 && tabIds?.[3]) return tabIds[3];
  if (hasLocalStorage()) {
    const storedTab = localStorage.getItem(storageKey);
    if (tabIds.includes(storedTab)) {
      return storedTab;
    }
  }
  return tabIds[0];
};

const getClosestTabId = (el) =>
  el?.closest("[data-mv-tab]")?.getAttribute("data-mv-tab") || null;

const injectTabIdIntoItid = (a, tabId) => {
  const alreadyAdded = !!(a?.search || "").match(`mv-${tabId}`);
  if (!alreadyAdded && /^(.*itid=(?:hp|sf)[-_])(.*)$/.test(a?.search)) {
    a.search = `${RegExp.$1}mv-${tabId}_${RegExp.$2}`;
  }
};

const handleItemIntersectionPrivately = ({
  ref,
  tabId,
  hasIntersected,
  addTrackedPosition
}) => {
  const html = ref.current;
  const sel = ".card.stream-item";
  const containerSel = `[data-mv-tab=${tabId}]`;
  const freq = 10;
  const options = { threshold: 0.15 };

  const callback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const label = Number(
          getIndex(harvestCandidates(entry.target, sel, containerSel))
        );
        addTrackedPosition(tabId, label);
        global?.window?.dataLayer?.push({
          event: "site-onpage-scroll-event",
          action: "onpage-scroll-impression",
          category: "onpage",
          label,
          moduleName: "multiview-feed"
        });
        observer.unobserve(entry.target);
      }
    });
  };

  const onIntersection = debounce(callback, 300);

  // eslint-disable-next-line compat/compat
  const observer = new IntersectionObserver(onIntersection, options);

  Array.from(html.querySelectorAll(sel))
    // NOTE: Add first and every freqth item if this position has not already been intersected
    .filter(
      (_, i) =>
        (i === 0 || i % freq === freq - 1) && !hasIntersected(tabId, i + 1)
    )
    .forEach((item) => {
      observer.observe(item);
    });

  return () => observer.disconnect();
};

const getDataByTabId = (tabId, tabData) => {
  return tabData.reduce((acc, data) => {
    if (data.tabId === tabId) acc = data;
    return acc;
  }, {});
};

const MultiViewProvider = ({ children }) => {
  const { globalContent } = useAppContext();
  const { xs } = useBreakpoints();
  // TODO: Remove all variant-related code when the test is over
  const variant = useAkamaiVariant();

  // TODO: get the initial list from feature flag endpoint
  const tabsToShow = fixTabsToShow([
    "top-stories",
    "latest",
    "foryou-flex-headlines",
    "ask-the-post-ai"
  ]);
  const tabData = useMultiviewTabData().filter(({ tabId }) =>
    tabsToShow.includes(tabId)
  );

  const isMultiView =
    !!globalContent?.enableMultiView && !!xs && tabData.length > 1;
  const initialTab = getInitialTab(tabData, variant);

  const [isReady, setReady] = useState(false);
  const [activeTab, setActiveTabPrivately] = useState(initialTab);

  const setActiveTab = (tab) => {
    if (hasLocalStorage() && variant !== 1)
      localStorage.setItem(storageKey, tab);
    setActiveTabPrivately(tab);
  };

  const [top, setTop] = useState("60px");

  const setScrollDepth = (tabId) => {
    const tab = getDataByTabId(tabId, tabData);
    set(tab, "config.scrollY", global?.window?.scrollY || 0);
  };

  const getScrollDepth = (tabId) => {
    const tab = getDataByTabId(tabId, tabData);
    return get(tab, "config.isAlwaysMounted", false)
      ? get(tab, "config.scrollY", 0)
      : undefined;
  };

  const [trackedPositions, setTrackedPositions] = useState(
    tabData.reduce((acc, { tabId }) => {
      acc[tabId] = [];
      return acc;
    }, {})
  );

  const hasIntersected = (tabId, value) =>
    trackedPositions?.[tabId]?.includes(value);

  const addTrackedPosition = (tabId, value) => {
    if (!hasIntersected(tabId, value))
      setTrackedPositions((prev) => {
        prev[tabId] = [...prev[tabId], value];
        return prev;
      });
  };

  const handleItemIntersection = (props) =>
    handleItemIntersectionPrivately({
      ...props,
      hasIntersected,
      addTrackedPosition
    });

  const setTabContentByIndex = (content, i) => {
    tabData[i].content = content;
  };

  const handlers = ["onTabChange", "onClick", "onUnload"].reduce((acc, key) => {
    acc[key] = (props) => {
      tabData.forEach(({ config: { handlers: tabHandlers, ...rest } }) => {
        const handler = get(tabHandlers, key);
        if (handler) handler({ ...props, ...rest });
      });
    };
    return acc;
  }, {});

  useEffect(() => {
    setReady(true);
  }, []);

  const [hotTopics, setHotTopics] = useState({});

  // START: event handlers
  useClick((ev) => {
    if (handlers?.onClick) {
      const a = ev?.target?.closest("a[href]");
      if (a) {
        const closestTabId = getClosestTabId(a);
        if (closestTabId) {
          injectTabIdIntoItid(a, closestTabId);
          handlers.onClick({ a, activeTab: closestTabId });
        }
      }
    }
  });

  useEffect(() => {
    if (handlers?.onTabChange) {
      handlers.onTabChange({ activeTab });
    }
  }, [activeTab, handlers]);

  useUnload(() => {
    if (handlers?.onUnload) {
      handlers.onUnload();
    }
  });
  // START: event handlers

  return (
    <MultiViewContext.Provider
      value={{
        isReady,
        isMultiView,
        tabData,
        initialTab,
        activeTab,
        setActiveTab,
        height: HEIGHT,
        top,
        setTop,
        setTabContentByIndex,
        setScrollDepth,
        getScrollDepth,
        handleItemIntersection,
        hotTopics,
        setHotTopics
      }}
    >
      {children}
    </MultiViewContext.Provider>
  );
};

function useMultiView() {
  return useContext(MultiViewContext);
}

MultiViewProvider.propTypes = {
  children: PropTypes.node
};

export { MultiViewProvider, useMultiView };
