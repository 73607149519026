import get from "lodash.get";
import {
  headlineAlignments,
  labelAlignments
} from "../../components/features/fronts/utilities/common-front-properties";
import presetGenerator from "./preset-generator";

// NOTE: Add renamed presets here
const legacyMap = {
  "Banner head": "Banner head (headline only)",
  "Top Stories -- Banner Head": "Banner head strip display"
};

const presets = {
  "": {},
  "=== ANCHORS/DISPLAYS ===": {},
  "Banner head strip display": {
    mobilePreset: "+ Image: Lede Display (image below head + caption/credit)",
    textAlignment: "left",
    labelAlignment: labelAlignments.center,
    inlineVideo: true,
    artOverlayShow: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: false,
    artPosition: "Art right of blurb",
    artSize: "X-Large",
    artAspectRatio: "3:2",
    captionContent: "Normal caption",
    headlineHide: false,
    headlineSize: "Huge",
    headlineStyle: "Bold",
    headlineAlignment: headlineAlignments.center,
    deckShow: true,
    blurbHide: false,
    bylineShow: true,
    timestampShow: true,
    relatedLinksPosition: "Default"
  },
  "Banner head (headline only)": {
    mobilePreset: "As is",
    textAlignment: "center",
    inlineVideo: true,
    artOverlayShow: true,
    alternateArtLinkEnable: true,
    artHide: true,
    headlineHide: false,
    headlineSize: "Huge",
    headlineStyle: "Bold",
    headlineAlignment: "inherit",
    blurbHide: true,
    bylineShow: false,
    timestampShow: false,
    relatedLinksPosition: "None",
    liveTickerHide: "true"
  },
  "Strip display -- X-Large art": {
    mobilePreset: "Modernized lede",
    textAlignment: "left",
    inlineVideo: true,
    artOverlayShow: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: false,
    artPosition: "Art right",
    artSize: "X-Large",
    artAspectRatio: "3:2",
    captionContent: "Normal caption",
    headlineHide: false,
    headlineSize: "Standard",
    headlineStyle: "Bold",
    headlineAlignment: "inherit",
    deckShow: false,
    blurbHide: false,
    bylineShow: true,
    timestampShow: true,
    actionsShow: "always",
    relatedLinksPosition: "Default"
  },
  "Art only": {
    mobilePreset: "HIDE ENTIRE FEATURE",
    textAlignment: "left",
    labelShow: false,
    inlineVideo: true,
    artOverlayShow: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: false,
    artPosition: "Art above head",
    artAspectRatio: "3:2",
    captionContent: "Credits only",
    headlineHide: true,
    blurbHide: true,
    bylineShow: false,
    timestampShow: false,
    relatedLinksPosition: "None",
    liveTickerHide: true
  },
  "Head + blurb + byline": {
    mobilePreset: "As is",
    textAlignment: "left",
    inlineVideo: true,
    artOverlayShow: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: true,
    artPosition: "Art right",
    artSize: "X-Large",
    artAspectRatio: "3:2",
    captionContent: "Normal caption",
    headlineHide: false,
    headlineSize: "Standard",
    headlineStyle: "Bold",
    headlineAlignment: "inherit",
    deckShow: false,
    blurbHide: false,
    bylineShow: true,
    timestampShow: true,
    actionsShow: "always",
    relatedLinksPosition: "Default"
  },
  "Head + Ticker, Hide Art": {
    mobilePreset: "LUF Ticker, Hide Art",
    textAlignment: "left",
    inlineVideo: true,
    artOverlayShow: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: true,
    artPosition: "Art right",
    artSize: "X-Large",
    artAspectRatio: "3:2",
    captionContent: "Normal caption",
    headlineHide: false,
    headlineSize: "Standard",
    headlineStyle: "Bold",
    headlineAlignment: "inherit",
    deckShow: false,
    blurbHide: true,
    bylineShow: false,
    timestampShow: false,
    actionsShow: "always",
    relatedLinksPosition: "Default",
    liveTickerHide: false,
    liveTickerNum: 3,
    liveTickerNumMobileOnly: 2
  },
  "Full art, with small head": {
    mobilePreset: "As is",
    textAlignment: "left",
    labelShow: false,
    inlineVideo: true,
    artOverlayShow: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: false,
    artPosition: "Art above head",
    artAspectRatio: "3:2",
    captionContent: "Credits only",
    headlineHide: false,
    headlineSize: "Small",
    blurbHide: true,
    bylineShow: false,
    timestampShow: false,
    actionsShow: "never",
    relatedLinksPosition: "None",
    liveTickerHide: true
  },
  "=== SMALL HEADLINES ===": {},
  "Related coverage": {
    mobilePreset: "As is",
    artHide: true,
    timestampShow: false,
    bylineShow: false,
    blurbHide: true,
    headlineSize: "Small",
    actionsShow: "never"
  },
  "Live ticker": {
    mobilePreset: "– Hide blurb and image",
    textAlignment: "left",
    labelShow: true,
    labelType: "Live Updates",
    labelPosition: "Above Headline",
    inlineVideo: false,
    artOverlayShow: false,
    alternateArtLinkEnable: false,
    artHide: true,
    headlineHide: false,
    headlineSize: "Small",
    headlineStyle: "Bold",
    headlineAlignment: "inherit",
    blurbHide: true,
    bylineShow: false,
    timestampShow: false,
    relatedLinksPosition: "Default",
    liveTickerHide: false,
    liveTickerNum: 2,
    liveTickerNumMobileOnly: 2
  },
  "Podcast episode": {
    mobilePreset: "As is",
    textAlignment: "left",
    inlineVideo: false,
    artOverlayShow: false,
    alternateArtLinkEnable: false,
    labelShow: true,
    labelType: "Kicker",
    labelSecondaryShow: true,
    labelSecondary: "Podcast",
    labelIcon: "Headphones",
    artHide: true,
    headlineHide: false,
    headlineSize: "Small",
    headlineStyle: "Bold",
    headlineAlignment: "inherit",
    blurbHide: true,
    bylineShow: false,
    timestampShow: false,
    relatedLinksPosition: "Default",
    liveTickerHide: true
  },
  Opinion: {
    mobilePreset: "As is",
    textAlignment: "left",
    labelShow: false,
    labelSecondaryShow: true,
    inlineVideo: false,
    artOverlayShow: false,
    alternateArtLinkEnable: false,
    artHide: false,
    artImageFallback: "headshot",
    artPosition: "Art right",
    artSize: "Mini",
    artAspectRatio: "1:1",
    artMakeItRound: "if-headshot",
    slideshowShow: false,
    captionContent: "None",
    headlineHide: false,
    headlineSize: "Small",
    headlineStyle: "Bold",
    headlineAlignment: "inherit",
    blurbHide: true,
    bylineShow: false,
    bylineShowOpinion: true,
    timestampShow: true,
    actionsShow: "never"
  },
  "=== OTHER ===": {},
  "Top Stories -- Breaking news strip": {
    mobilePreset: "+ Image: Display (image above + caption/credit)",
    textAlignment: "left",
    labelShow: true,
    labelType: "Live Updates",
    labelPosition: "Above Headline",
    label: "Breaking News",
    inlineVideo: false,
    artOverlayShow: false,
    alternateArtLinkEnable: false,
    artHide: false,
    artPosition: "Art left",
    artSize: "Small",
    artAspectRatio: "3:2",
    headlineHide: false,
    headlineSize: "Extra large",
    headlineStyle: "Bold",
    headlineAlignment: "inherit",
    blurbHide: false,
    bylineShow: true,
    timestampShow: true,
    relatedLinksPosition: "Default",
    liveTickerHide: true
  },
  "Top Stories -- Strip display": {
    mobilePreset: "Modernized lede",
    textAlignment: "left",
    inlineVideo: true,
    artOverlayShow: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: false,
    artPosition: "Art right",
    artSize: "Large",
    artAspectRatio: "3:2",
    captionContent: "Normal caption",
    headlineHide: false,
    headlineSize: "Large",
    headlineStyle: "Bold",
    headlineAlignment: "inherit",
    deckShow: false,
    blurbHide: false,
    bylineShow: true,
    timestampShow: true,
    relatedLinksPosition: "Default"
  },
  "Top Stories -- Strip with small photo": {
    mobilePreset: "+ Image: Display – Hide blurb",
    textAlignment: "left",
    inlineVideo: false,
    artOverlayShow: false,
    alternateArtLinkEnable: false,
    artHide: false,
    artPosition: "Art right",
    artSize: "Small",
    artAspectRatio: "3:2",
    captionContent: "None",
    headlineHide: false,
    headlineSize: "Large",
    headlineStyle: "Bold",
    headlineAlignment: "inherit",
    blurbHide: true,
    bylineShow: true,
    timestampShow: true,
    relatedLinksPosition: "Default",
    liveTickerHide: true
  },
  "Top Stories -- Head + image above": {
    // mobilePreset: "– Hide blurb and image",
    textAlignment: "left",
    inlineVideo: false,
    artOverlayShow: false,
    includeDurationOrLive: true,
    alternateArtLinkEnable: false,
    artHide: false,
    artPosition: "Art above head",
    artAspectRatio: "3:2",
    captionContent: "None",
    headlineHide: false,
    headlineSize: "Medium",
    headlineStyle: "Bold",
    headlineAlignment: "inherit",
    blurbHide: true,
    bylineShow: true,
    timestampShow: true,
    relatedLinksPosition: "Default",
    liveTickerHide: true
  },
  "Top Stories -- Head + byline + thumbnail": {
    // mobilePreset: "+ Image right – Hide blurb",
    textAlignment: "left",
    inlineVideo: false,
    artOverlayShow: false,
    alternateArtLinkEnable: false,
    artHide: false,
    artPosition: "Art right of head",
    artSize: "Large",
    artAspectRatio: "3:2",
    captionContent: "None",
    headlineHide: false,
    headlineSize: "Medium",
    headlineStyle: "Bold",
    headlineAlignment: "inherit",
    blurbHide: true,
    bylineShow: true,
    timestampShow: true,
    relatedLinksPosition: "Default",
    liveTickerHide: true
  },
  Display: {
    mobilePreset: "+ Image: Display – Hide blurb",
    textAlignment: "left",
    inlineVideo: true,
    artOverlayShow: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: false,
    artPosition: "Art above head",
    artSize: "Large",
    artAspectRatio: "3:2",
    captionContent: "Normal caption",
    headlineHide: false,
    headlineSize: "Large",
    headlineStyle: "Bold",
    headlineAlignment: "inherit",
    blurbHide: true,
    bylineShow: true,
    timestampShow: true,
    relatedLinksPosition: "Default"
  },
  "Display -- feature": {
    mobilePreset: "+ Image: Display (image above + caption/credit)",
    textAlignment: "left",
    inlineVideo: true,
    artOverlayShow: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: false,
    artPosition: "Art above head",
    artAspectRatio: "3:2",
    captionContent: "Normal caption",
    headlineHide: false,
    headlineSize: "Large",
    headlineStyle: "Regular",
    headlineAlignment: "inherit",
    blurbHide: false,
    bylineShow: true,
    timestampShow: true,
    relatedLinksPosition: "Default"
  },
  "Display – Extra wide": {
    mobilePreset: "+ Image: Display – Hide blurb",
    textAlignment: "left",
    inlineVideo: true,
    artOverlayShow: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: false,
    artPosition: "Art right",
    artSize: "Large",
    artAspectRatio: "3:2",
    captionContent: "Normal caption",
    headlineHide: false,
    headlineSize: "Medium",
    headlineStyle: "Bold",
    headlineAlignment: "inherit",
    blurbHide: false,
    bylineShow: true,
    timestampShow: true,
    relatedLinksPosition: "Default"
  },
  "Display – Extra wide, Feature": {
    mobilePreset: "+ Image: Display (image above + caption/credit)",
    textAlignment: "left",
    inlineVideo: true,
    artOverlayShow: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: false,
    artPosition: "Art right",
    artSize: "Large",
    artAspectRatio: "3:2",
    captionContent: "Normal caption",
    headlineHide: false,
    headlineSize: "Large",
    headlineStyle: "Regular",
    headlineAlignment: "inherit",
    blurbHide: false,
    bylineShow: true,
    timestampShow: true,
    relatedLinksPosition: "Default"
  },
  "Feed head": {
    mobilePreset: "Feed head",
    textAlignment: "left",
    inlineVideo: false,
    artOverlayShow: false,
    alternateArtLinkEnable: false,
    artHide: true,
    captionContent: "None",
    headlineHide: false,
    headlineSize: "Tiny",
    headlineAlignment: "inherit",
    blurbHide: true,
    bylineShow: false,
    timestampShow: false,
    relatedLinksPosition: "None",
    liveTickerHide: true
  },
  "Feed head + mug": {
    mobilePreset: "Feed head + mug",
    textAlignment: "left",
    inlineVideo: false,
    artOverlayShow: false,
    alternateArtLinkEnable: false,
    artHide: false,
    artPosition: "Art right",
    artSize: "X-Small",
    artAspectRatio: "1:1",
    slideshowShow: false,
    captionContent: "None",
    headlineHide: false,
    headlineSize: "Tiny",
    headlineAlignment: "inherit",
    blurbHide: true,
    bylineShow: false,
    timestampShow: false,
    relatedLinksPosition: "None",
    liveTickerHide: true
  },
  "Head + blurb": {
    mobilePreset: "– Hide blurb and image",
    textAlignment: "left",
    inlineVideo: false,
    artOverlayShow: false,
    alternateArtLinkEnable: false,
    artHide: true,
    headlineHide: false,
    headlineSize: "Small",
    headlineStyle: "Bold",
    headlineAlignment: "inherit",
    blurbHide: false,
    bylineShow: true,
    timestampShow: true,
    relatedLinksPosition: "Default",
    liveTickerHide: true
  },
  "Head + byline": {
    mobilePreset: "– Hide blurb and image",
    textAlignment: "left",
    inlineVideo: false,
    artOverlayShow: false,
    alternateArtLinkEnable: false,
    artHide: true,
    headlineHide: false,
    headlineSize: "Small",
    headlineStyle: "Bold",
    headlineAlignment: "inherit",
    blurbHide: true,
    bylineShow: true,
    timestampShow: true,
    relatedLinksPosition: "Default",
    liveTickerHide: true
  },
  "Head + image above": {
    mobilePreset: "– Hide blurb and image",
    textAlignment: "left",
    inlineVideo: false,
    artOverlayShow: false,
    includeDurationOrLive: true,
    alternateArtLinkEnable: false,
    artHide: false,
    artPosition: "Art above head",
    artAspectRatio: "3:2",
    captionContent: "None",
    headlineHide: false,
    headlineSize: "Small",
    headlineStyle: "Bold",
    headlineAlignment: "inherit",
    blurbHide: true,
    bylineShow: true,
    timestampShow: true,
    relatedLinksPosition: "Default",
    liveTickerHide: true
  },
  "Instagram flipper": {
    mobilePreset: "As is",
    textAlignment: "left",
    labelShow: false,
    inlineVideo: true,
    artOverlayShow: true,
    includeDurationOrLive: true,
    alternateArtLinkEnable: true,
    artHide: false,
    artPosition: "Art above head",
    artAspectRatio: "1:1",
    artUseHiRes: true,
    slideshowAutoplay: false,
    slideshowDots: "all",
    captionContent: "None",
    headlineHide: true,
    blurbHide: true,
    bylineShow: false,
    timestampShow: false,
    relatedLinksPosition: "None",
    ctaLabelShow: true,
    ctaLabel: "Read the story",
    ctaLabelAlignment: "center"
  },
  "Related links only": {
    mobilePreset: "As is",
    textAlignment: "left",
    labelShow: false,
    inlineVideo: false,
    artOverlayShow: false,
    alternateArtLinkEnable: false,
    artHide: true,
    headlineHide: true,
    blurbHide: true,
    bylineShow: false,
    timestampShow: false,
    relatedLinksPosition: "Default",
    relatedLinksNum: 2,
    relatedLinksLabelType: "Kicker",
    relatedLinksLabel: "MORE COVERAGE",
    liveTickerHide: false
  }
};

/*
 * @param {object} customFields - The customFields
 *
 * @returns {string} - The canonical string of the mobilePreset.
 *
 * NOTE: Uses legacyMap as a layer of protection against name changes
 */
export const getLastPickedPreset = (customFields) =>
  legacyMap[get(customFields, "lastPickedPreset", "")]
    ? legacyMap[customFields.lastPickedPreset]
    : customFields.lastPickedPreset;

export default Object.keys(presets).map((name) =>
  presetGenerator(name, presets[name])
);
