import get from "lodash.get";
import { getChainIndex } from "~/components/layouts/homepage.helpers";

export const FOR_YOU_SURFACE_MV_TAB = "assembler-fy-mv-tab";
export const FOR_YOU_SURFACE_DEFAULT = "assembler-fy";

/**
 * Real-time Events (RTE) attributes for tracking information,
 *  intended for the child stories of a common container.
 * @param {object} content of the card
 * @returns data attrs relevant to RTE for attaching to a single card
 */
export const getRTEChildAttrs = (content) => {
  const content_id = get(content, "_id") ?? get(content, "tracking.post_id");
  const article_id =
    get(content, "additional_properties.for_you.article_id") ??
    (content_id ? `contentapi://${content_id}` : undefined);

  if (!article_id) return {};
  return {
    "data-articleid": article_id
  };
};

const tableKeyIndexes = {
  table0: 0,
  table1: 1,
  table2: 2,
  table3: 3,
  table4: 4,
  table5: 5,
  table6: 6,
  table7: 7,
  table8: 8,
  tableAd1: null, // Indexing is not supported for this
  table9: 9,
  allcontent: 1
};

/**
 *
 * @param {object} layoutCtx from SandwichLayoutContext
 * @param {object} tableContext from TableLayoutContext
 * @returns This is a string that consistently identifies where within the Washington Post website the
 *  module is located. A module should have a consistent name across all users that view it, and should be
 *  globally unique across all page types. For example in our personalized homepage test, the layout will
 *  be consistent with the “More Top Stories” being replaced with a personalized module. The same module
 *  name (e.g. “homepage_module_8” or “homepage_below_top_table”) should be applied to both versions.
 */
export const getRTEModulePosition = (layoutCtx, tableContext) => {
  const { tableKey } = layoutCtx;
  const { ref: tableRef } = tableContext;

  const cardsVisible = tableRef?.current?.querySelector(".card");
  if (!cardsVisible) return null;

  const tableIndex = tableKeyIndexes[tableKey]?.toString()?.padStart(2, "0");
  const chainIndex = parseInt(getChainIndex(tableRef?.current));

  return chainIndex && tableIndex ? `${chainIndex}${tableIndex}` : "";
};

/**
 *
 * @param {object} chainContext from ChainContext
 * @param {object} tableContext from TableLayoutContext
 * @returns A unique string identifying what category of articles are being proposed.
 *  This will typically correlate to the title shown to the reader (e.g. “Most read”= “most_read” or
 *  “Your followed topics”). If the same reason for showing articles occurs in two places, the string
 *  should be the same.
 */
export const getRTEModuleCategory = (chainContext, tableContext) => {
  const { assignedLinkGroup: chainGroup, displayName: chainName } =
    chainContext;
  const { assignedLinkGroup: tableGroup, labelText } = tableContext;
  const label = labelText
    ?.toLowerCase()
    .replace(/[^\w\s]|_/g, "")
    .replace(/\s+/g, "-");
  return label ?? tableGroup ?? chainGroup ?? chainName;
};

/**
 *
 * @param {object[]} items of the table
 * @param {object} context from AppContext
 * @param {string} modulePosition If there are multiple modules on the same page, what order are they in?
 * @returns This is a string that consistently identifies where within the Washington Post website the
 *  module is located. A module should have a consistent name across all users that view it, and should be
 *  globally unique across all page types. For example in our personalized homepage test, the layout will
 *  be consistent with the “More Top Stories” being replaced with a personalized module. The same module
 *  name (e.g. “homepage_module_8” or “homepage_below_top_table”) should be applied to both versions.
 */
export const getRTESurface = (items, context, modulePosition) => {
  const fySurface = get(items, "0.additional_properties.for_you.surface");
  const section = context?.globalContent?.siteServiceSection;
  const rteSurface =
    section && modulePosition && `${section}_${modulePosition}`;
  return fySurface ?? rteSurface;
};

/**
 * Real-time Events (RTE) attributes for tracking information,
 *  intended for the common container of several child stories.
 *
 * NOTE: For now, personalized modules get `fy` attributes, and non-personalized modules get `rte` attributes.
 *
 * @param {object[]} items of the table
 * @param {string} surface This is a string that consistently identifies where within the Washington Post website the
 *  module is located. A module should have a consistent name across all users that view it, and should be
 *  globally unique across all page types. For example in our personalized homepage test, the layout will
 *  be consistent with the “More Top Stories” being replaced with a personalized module. The same module
 *  name (e.g. “homepage_module_8” or “homepage_below_top_table”) should be applied to both versions.
 * @param {string} moduleCategory A unique string identifying what category of articles are being proposed.
 *  This will typically correlate to the title shown to the reader (e.g. “Most read”= “most_read” or
 *  “Your followed topics”). If the same reason for showing articles occurs in two places, the string
 *  should be the same.
 * @param {string} modulePosition If there are multiple modules on the same page, what order are they in?
 * @returns data attrs relevant to RTE for attaching to a parent container that includes a number of cards.
 */
export const getRTEParentAttrs = (
  items,
  surface,
  moduleCategory,
  modulePosition,
  isPersonalized
) => {
  const request_id = get(
    items,
    "0.additional_properties.for_you.request_id",
    undefined
  );

  return {
    ...(isPersonalized
      ? {
          ...(request_id ? { "data-fy-request-id": request_id } : {}),
          ...(surface ? { "data-fy-surface": surface } : {})
        }
      : {
          ...(request_id ? { "data-rte-request-id": request_id } : {}),
          ...(surface ? { "data-rte-surface": surface } : {}),
          ...(moduleCategory
            ? { "data-rte-module-category": moduleCategory }
            : {}),
          ...(modulePosition
            ? { "data-rte-module-position": modulePosition }
            : {})
        })
  };
};
