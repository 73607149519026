import { sendGAEvent } from "@washingtonpost/site-analytics";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";

/**
 * Component to send GA events for Real-Time Events (RTE).
 *
 * @param {object} param0
 * @param {object} param0.attrsRTE the RTE attributes to send
 * @returns
 */
export const RealTimeEvents = ({ attrsRTE = {}, children }) => {
  const {
    "data-fy-request-id": fyRequestId,
    "data-rte-surface": rteSurface,
    "data-rte-module-position": rteModulePosition,
    "data-rte-module-category": rteModuleCategory
  } = attrsRTE;

  const [rteSent, setRTESent] = useState(false);

  const selectorFY = useMemo(
    () => `[data-fy-request-id="${fyRequestId}"]`,
    [fyRequestId]
  );
  const selectorRTE = useMemo(
    () => `[data-rte-surface="${rteSurface}"]`,
    [rteSurface]
  );

  /**
   * Send a GA event for FY.
   */
  useEffect(() => {
    const cardsVisible = document
      .querySelector(selectorFY)
      ?.querySelector(".card");
    if (typeof document !== "undefined" && cardsVisible && !rteSent) {
      sendGAEvent("scroll_viewable", "fy", "fy", {
        selector: selectorFY,
        event: "fy-shown"
      });
      setRTESent(true);
    }
  }, [selectorFY, children, rteSent]);

  /**
   * Send a GA event for RTE.
   */
  useEffect(() => {
    const cardsVisible = document
      .querySelector(selectorRTE)
      ?.querySelector(".card");
    if (
      typeof document !== "undefined" &&
      cardsVisible &&
      !rteSent &&
      selectorRTE &&
      rteSurface &&
      rteModulePosition &&
      rteModuleCategory &&
      // NOTE: For now, personalized modules get `fy` events, and non-personalized modules get `rte` events.
      !fyRequestId
    ) {
      sendGAEvent("scroll_viewable", "rte", "rte", {
        selector: selectorRTE,
        event: "headline-ready"
      });
      setRTESent(true);
    }
  }, [
    selectorRTE,
    children,
    rteSent,
    fyRequestId,
    rteSurface,
    rteModulePosition,
    rteModuleCategory
  ]);

  return children;
};

RealTimeEvents.propTypes = {
  attrsRTE: PropTypes.object,
  children: PropTypes.node
};
