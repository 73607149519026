import { useEffect, useRef, useState } from "react";
import { useAppContext } from "fusion-context";
import { Box, Tabs, theme, styled } from "@washingtonpost/wpds-ui-kit";
import Proptypes from "prop-types";
import { useMultiView } from "~/shared-components/MultiViewContext";
import { useScroll } from "~/components/utilities/use-event-handlers";
import { useAdLoad } from "~/components/features/fronts/zeus/hp-zeus.helpers";
import LiveUpdateHpAlert from "~/shared-components/features/fronts/alert/default";
import LiveUpdateVideoLiveBar from "~/shared-components/features/fronts/video-live-bar/default";
import { TabContent } from "./TabContent";

const TabWrapper = styled(Box, {
  width: "100%",
  zIndex: 4,
  backgroundColor: theme.colors.gray700,
  borderBottom: `1px solid ${theme.colors.gray300}`,
  variants: {
    isSticky: {
      true: {
        position: "sticky"
      },
      false: {
        paddingTop: theme.space["075"]
      }
    }
  },
  "& div[role=tablist]": {
    boxShadow: "none",
    columnGap: theme.space["200"],
    "& svg[role=img]": {
      width: theme.sizes["125"],
      height: theme.sizes["125"]
    }
  }
});

const TabsTrigger = styled(Tabs.Trigger, {
  paddingTop: 0,
  paddingBottom: theme.space["025"],
  "&[data-state=active]": {
    "&:after": {
      borderBottom: `2px solid ${theme.colors.primary}`
    }
  },
  "&[data-state=inactive]": {
    color: theme.colors.gray40
  }
});

const TabList = styled(Tabs.List, {
  color: theme.colors.gray40,
  padding: 0,
  fontSize: theme.fontSizes["100"]
});

const TabLabel = styled(Box, {
  padding: "$050 0"
});

const sendToggleToDataLayer = (oldTab, newTab) => {
  global?.window?.dataLayer?.push({
    event: "site-onpage-click-event",
    category: "onpage",
    action: "onpage-click-event",
    label: "multiview-toggle",
    appSection: newTab,
    eventDimension: oldTab
  });
};

const scrollToDepth = (spacer, isSticky, scrollDepth) => {
  const isNumber = /^[0-9]+$/;
  let scrollTo;
  if (isNumber.test(scrollDepth)) {
    scrollTo = scrollDepth;
  } else if (isSticky) {
    const getNavAdjustment = () => {
      const nav = document?.querySelector("nav");
      return nav ? nav.offsetTop + nav.offsetHeight : 0;
    };
    const navAdjustment = getNavAdjustment();
    scrollTo = navAdjustment ? spacer.offsetTop - navAdjustment : 0;
  }
  if (isNumber.test(scrollTo)) {
    setTimeout(() => {
      global?.window?.scrollTo({
        top: scrollTo,
        behavior: "instant"
      });
    }, 0);
  }
};

const MultiViewTabs = () => {
  const ref = useRef();
  const scrollRef = useRef();
  const [isMounted, setMounted] = useState(false);
  const { isAdmin } = useAppContext();
  const {
    tabData,
    activeTab,
    setActiveTab,
    top,
    setTop,
    setScrollDepth,
    getScrollDepth
  } = useMultiView();

  const [isSticky, setSticky] = useState(true);

  const handleValueChange = (val) => {
    setScrollDepth(activeTab);
    if (!isAdmin) sendToggleToDataLayer(activeTab, val);
    setActiveTab(val);
    scrollToDepth(scrollRef.current, isSticky, getScrollDepth(val));
  };

  const doSticky = () => {
    if (ref?.current && global?.window && global?.document) {
      const offsetHeight = document.querySelector("nav")?.offsetHeight || 0;
      if (ref.current.offsetTop - window.scrollY <= offsetHeight) {
        setSticky(true);
        setTop(`${offsetHeight}px`);
      } else {
        setSticky(false);
        setTop(0);
      }
    }
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  useAdLoad(doSticky, { slots: ["banner_top"] });
  useScroll(doSticky, { interval: 10 });

  return (
    <Tabs.Root
      ref={ref}
      key={isMounted}
      value={activeTab}
      onValueChange={handleValueChange}
    >
      <TabWrapper isSticky={isSticky} css={{ top }}>
        <TabList aria-label="Navigation" density="compact" align="center">
          {tabData.map(({ name, tabId }) => {
            return (
              <TabsTrigger value={tabId} key={tabId}>
                <TabLabel>
                  <span>{name}</span>
                </TabLabel>
              </TabsTrigger>
            );
          })}
        </TabList>
      </TabWrapper>
      <div ref={scrollRef} />
      {activeTab !== "ask-the-post-ai" && <LiveUpdateHpAlert />}
      {activeTab !== "ask-the-post-ai" && <LiveUpdateVideoLiveBar />}
      {tabData.map(({ tabId, ...rest }) => {
        return <TabContent key={tabId} tabId={tabId} {...rest} />;
      })}
    </Tabs.Root>
  );
};

MultiViewTabs.propTypes = {
  tabData: Proptypes.arrayOf(
    Proptypes.shape({
      icon: Proptypes.object,
      name: Proptypes.string,
      tabId: Proptypes.string,
      content: Proptypes.object
    })
  )
};

export default MultiViewTabs;
