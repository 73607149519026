import dynamic from "next/dynamic";

// PropTypes
import { HomepagePropTypes as HomepageFields } from "../proptypes/homepage";
import { TopTablePropTypes as TopTableFields } from "../proptypes/top-table";
import { FlexFeaturePropTypes as FlexFeatureFields } from "../proptypes/flex-feature";
import { TopperPropTypes as SectionTopperFields } from "../proptypes/topper";
import { HotTopicsPropTypes as HotTopicsFields } from "../proptypes/hot-topics";
import { ZeusPropTypes as ZeusFields } from "../proptypes/zeus";
import { CustomHTMLPropTypes as CustomHTMLFields } from "../proptypes/custom-html";
import { NewsletterPropTypes as NewsletterFields } from "../proptypes/newsletter";
import { CarouselPropTypes as CarouselFields } from "../proptypes/carousel";
import { DiversionsPropTypes as DiversionsFields } from "../proptypes/diversions";
import { StateNavDropdownPropTypes as StateNavDropdownFields } from "../proptypes/state-nav-dropdown";
import { BrandConnectAdPropTypes as BrandConnectAdFields } from "../proptypes/in-line-brandconnect-ad";
import { InTableAdPropTypes as InTableAdFields } from "../proptypes/in-table-ad";
import { InTableHabitTilesPropTypes as InTableHabitTilesFields } from "../proptypes/in-table-habit-tiles";

import WapoLazyChild from "~/shared-components/WapoLazyChild";
// Components
import ErrorBoundary from "../shared-components/ErrorBoundary";
import Homepage from "../components/layouts/homepage";
import TopTable from "~/shared-components/chains/top-table/default";

// Presets
import presets, { getLastPickedPreset } from "../presets/presets";
import miniPresets, { getLastPickedMiniPreset } from "../presets/mini-presets";
import mobilePresets, { getMobilePreset } from "../presets/mobile-presets";
import { getHideAndShowPresets } from "../presets/utils";

// Carousel presets
import { getCarouselPresets } from "../components/features/fronts/carousel/presets";

// Utils
import { getToolbarInfo as getTopTableToolbarInfo } from "~/shared-components/chains/top-table/toolbar";
import { Section } from "./Section";

// Dynamic imports
// const TopTable = dynamic(() =>
//   import("../components/chains/top-table/default")
// );
const FlexFeature = dynamic(() =>
  import("../components/features/fronts/flex-feature/default")
);
const OverrideFormWidget = dynamic(() =>
  import("../components/features/fronts/flex-feature/OverrideFormWidget")
);
const AutoUpdate = dynamic(() =>
  import("../components/features/fronts/force-refresh/AutoUpdate")
);
const ForceUpdate = dynamic(() =>
  import("~/shared-components/features/fronts/force-refresh/ForceUpdate")
);
const HomepageMasthead = dynamic(() =>
  import("../components/features/fronts/masthead/default")
);
const HPHeaderNav = dynamic(() =>
  import("../components/features/fronts/hpHeaderNav/default")
);
const HeaderNav = dynamic(() =>
  import("../components/features/global/HeaderNav/default")
);
const SectionTopper = dynamic(() =>
  import("../components/features/fronts/topper/default")
);
const HotTopics = dynamic(() =>
  import("../components/features/fronts/hot-topics/default")
);
const FusionZeus = dynamic(() =>
  import("../components/features/fronts/zeus/default")
);
const GlobalFooter = dynamic(() =>
  import("../components/features/global/Footer")
);
const Diversions = dynamic(() =>
  import("../components/features/fronts/diversions/default")
);
const BrandConnectAd = dynamic(() =>
  import("../components/features/fronts/in-line-brandconnect-ad/default")
);
const InTableAd = dynamic(() =>
  import("../components/features/fronts/in-table-ad/default")
);
const InTableHabitTiles = dynamic(() =>
  import("../components/features/fronts/in-table-habit-tiles/default")
);
const CustomHTML = dynamic(() =>
  import("../components/features/fronts/customHTML/default")
);
const NewsletterSignUp = dynamic(() =>
  import("../components/features/fronts/newsletter-module/default")
);
const InlineSubsModule = dynamic(() =>
  import("../components/features/inline-subs-module/default")
);
const Carousel = dynamic(() =>
  import("../components/features/fronts/carousel/default")
);
const NewsletterContentConfig = dynamic(() =>
  import("../components/features/fronts/flex-feature/NewsletterContentConfig")
);
const GlobalContentConfig = dynamic(() =>
  import("../components/features/fronts/flex-feature/GlobalContentConfig")
);
const StateNavDropdown = dynamic(() =>
  import("../components/features/fronts/state-nav-dropdown/default")
);
const HabitTiles = dynamic(() =>
  import("../components/features/fronts/habit-tiles/default")
);

const sectionChildFeatures = [
  "top-table",
  "fronts/hpHeaderNav",
  "global/HeaderNav",
  "fronts/topper",
  "fronts/hot-topics",
  "fronts/zeus",
  "fronts/masthead",
  "fronts/auto-update",
  "fronts/force-update",
  "fronts/customHTML",
  "inline-subs-module/default",
  "global/Footer",
  "the-7-chain",
  "fronts/habit-tiles"
];

const chainChildFeatures = [
  "fronts/flex-feature",
  "fronts/diversions",
  "fronts/newsletter-module",
  "fronts/carousel",
  "fronts/in-line-brandconnect-ad",
  "fronts/state-nav-dropdown",
  "fronts/in-table-ad",
  "fronts/in-table-habit-tiles"
];

const FLEX_SIBLING_FEATURES = [
  "fronts/flex-feature",
  "fronts/newsletter",
  "fronts/newsletter-module",
  "fronts/carousel",
  "fronts/in-line-brandconnect-ad",
  "fronts/state-nav-dropdown",
  "fronts/in-table-ad",
  "fronts/in-table-habit-tiles"
];

// eslint-disable-next-line react/display-name
const WithBoundary = (Component) => (props) =>
  (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );

// eslint-disable-next-line react/display-name
const WithLazyLoad = (Component) => (props) =>
  (
    <WapoLazyChild
      offsetTop={300}
      offsetBottom={300}
      throttle={150}
      // eslint-disable-next-line react/prop-types
      eager={props?.customFields?.disableLazyLoading}
    >
      <Component {...props} />
    </WapoLazyChild>
  );

const presetHelpers = {
  getLastPickedPreset,
  getLastPickedMiniPreset,
  getMobilePreset
};

// put your features here
export const getComponentMap = (disableLazyLoading) => {
  const WithLazyLoadOrFrag = disableLazyLoading
    ? (Component) => Component
    : WithLazyLoad;

  return {
    root: {
      fields: HomepageFields,
      fieldComponents: { siteServiceSection: GlobalContentConfig }
    },
    homepage: {
      Component: WithBoundary(Homepage)
    },
    section: {
      Component: Section,
      childFeatures: sectionChildFeatures
    },
    "top-table": {
      Component: WithBoundary(WithLazyLoadOrFrag(TopTable)),
      fields: TopTableFields,
      toolbarHelper: { getToolbarInfo: getTopTableToolbarInfo },
      childFeatures: chainChildFeatures,
      siblingFeatures: sectionChildFeatures,
      name: "Multi-Table Chain",
      icon: "board"
    },
    "the-7-chain": {
      Component: WithBoundary(TopTable),
      fields: TopTableFields,
      toolbarHelper: { getToolbarInfo: getTopTableToolbarInfo },
      childFeatures: chainChildFeatures,
      siblingFeatures: sectionChildFeatures,
      name: "The 7 Chain",
      icon: "seven",
      collection: "chains"
    },
    "fronts/flex-feature": {
      Component: FlexFeature,
      fields: FlexFeatureFields,
      fieldComponents: { contentConfig: OverrideFormWidget },
      siblingFeatures: FLEX_SIBLING_FEATURES,
      name: "Flex Feature",
      icon: "feature",
      isGridItem: true,
      presetOptions: { presets, mini: miniPresets, mobile: mobilePresets },
      presetHelpers
    },
    "fronts/hpHeaderNav": {
      Component: WithBoundary(HPHeaderNav),
      fields: HPHeaderNav.propTypes,
      siblingFeatures: sectionChildFeatures,
      name: "HP Header Nav",
      icon: "header"
    },
    "global/HeaderNav": {
      Component: WithBoundary(HeaderNav),
      siblingFeatures: sectionChildFeatures,
      name: "Global Header Nav",
      icon: "header",
      fields: {}
    },
    "fronts/topper": {
      Component: WithBoundary(SectionTopper),
      siblingFeatures: sectionChildFeatures,
      name: "Section Topper",
      icon: "calendar",
      fields: SectionTopperFields
    },
    "fronts/hot-topics": {
      Component: WithBoundary(HotTopics),
      siblingFeatures: sectionChildFeatures,
      name: "Live/Trending Bar",
      icon: "whatshot",
      fields: HotTopicsFields
    },
    "fronts/zeus": {
      Component: WithBoundary(FusionZeus),
      fields: ZeusFields,
      siblingFeatures: sectionChildFeatures,
      name: "Zeus Ad (Fronts)",
      icon: "atm"
    },
    "fronts/masthead": {
      Component: WithBoundary(HomepageMasthead),
      fields: HomepageMasthead.propTypes,
      siblingFeatures: sectionChildFeatures,
      name: "Masthead",
      icon: "calendar"
    },
    "fronts/auto-update": {
      Component: WithBoundary(AutoUpdate),
      siblingFeatures: sectionChildFeatures,
      name: "Auto Update",
      icon: "refresh"
    },
    "fronts/force-update": {
      Component: WithBoundary(ForceUpdate),
      siblingFeatures: sectionChildFeatures,
      name: "Force Update",
      icon: "refresh"
    },
    "fronts/diversions": {
      Component: WithBoundary(Diversions),
      fields: DiversionsFields,
      name: "Diversions",
      icon: "tv",
      isGridItem: true
    },
    "fronts/customHTML": {
      Component: WithBoundary(CustomHTML),
      fields: CustomHTMLFields,
      name: "Custom HTML",
      icon: "code"
    },
    "fronts/newsletter-module": {
      Component: WithBoundary(NewsletterSignUp),
      fields: NewsletterFields,
      fieldComponents: { contentConfig: NewsletterContentConfig },
      name: "Newsletter",
      icon: "newspaper",
      isGridItem: true,
      siblingFeatures: FLEX_SIBLING_FEATURES,
      presetOptions: getHideAndShowPresets(),
      presetHelpers
    },
    "fronts/newsletter": {
      Component: WithBoundary(NewsletterSignUp),
      fields: NewsletterFields,
      fieldComponents: { contentConfig: NewsletterContentConfig },
      name: "Newsletter",
      icon: "newspaper",
      isGridItem: true,
      siblingFeatures: FLEX_SIBLING_FEATURES
    },
    "fronts/carousel": {
      Component: WithBoundary(Carousel),
      fields: CarouselFields,
      fieldComponents: { contentConfig: OverrideFormWidget },
      name: "Carousel",
      icon: "newspaper",
      isGridItem: true,
      siblingFeatures: FLEX_SIBLING_FEATURES,
      presetOptions: getCarouselPresets(),
      presetHelpers
    },
    "inline-subs-module/default": {
      Component: WithBoundary(InlineSubsModule),
      fields: InlineSubsModule.propTypes,
      siblingFeatures: sectionChildFeatures,
      name: "Inline Subs Module"
    },
    "global/Footer": {
      Component: WithBoundary(GlobalFooter),
      fields: GlobalFooter.propTypes,
      siblingFeatures: sectionChildFeatures,
      name: "Footer",
      icon: "horizontalSplit"
    },
    "fronts/in-line-brandconnect-ad": {
      Component: WithBoundary(BrandConnectAd),
      fields: BrandConnectAdFields,
      siblingFeatures: FLEX_SIBLING_FEATURES,
      name: "In-Line BrandConnect Ad",
      icon: "atm",
      isGridItem: true
    },
    "fronts/state-nav-dropdown": {
      Component: WithBoundary(StateNavDropdown),
      fields: StateNavDropdownFields,
      siblingFeatures: FLEX_SIBLING_FEATURES,
      name: "State Nav Dropdown",
      icon: "",
      isGridItem: true,
      presetOptions: { presets, mini: miniPresets, mobile: mobilePresets },
      presetHelpers
    },
    "fronts/in-table-ad": {
      Component: WithBoundary(InTableAd),
      fields: InTableAdFields,
      siblingFeatures: FLEX_SIBLING_FEATURES,
      name: "In-Table Ad",
      icon: "atm",
      isGridItem: true,
      presetOptions: getHideAndShowPresets(),
      presetHelpers
    },
    "fronts/in-table-habit-tiles": {
      Component: WithBoundary(InTableHabitTiles),
      fields: InTableHabitTilesFields,
      siblingFeatures: FLEX_SIBLING_FEATURES,
      name: "In-Table Habit Tiles (Apps only)",
      icon: "board",
      isGridItem: true,
      presetOptions: getHideAndShowPresets(),
      presetHelpers
    },
    "fronts/habit-tiles": {
      Component: WithBoundary(HabitTiles),
      siblingFeatures: sectionChildFeatures,
      name: "Habit Tiles (Apps only)",
      icon: "board"
    }
  };
};
